import { Analytics } from '@vercel/analytics/react'
import '@/styles/tailwind.css'
import 'focus-visible'
import localFont from 'next/font/local'

// TODO: purchase license when go live
const shift = localFont({
  src: '../../public/fonts/ShiftTRIAL-Medium.otf',
  variable: '--font-shift',
})

const figtree = localFont({
  src: '../../public/fonts/Figtree-Regular.ttf',
  variable: '--font-figtree',
})

export default function App({ Component, pageProps }) {
  // TODO: decide on best tag (main, vs something else), possibly just refactor fonts
  return (
    <main lang="en" className={`${shift.variable} ${figtree.variable}`}>
      <Component {...pageProps} />
      <Analytics />
    </main>
  )
}
